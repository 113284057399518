export default {
  methods: {
    nl2br: (str, is_xhtml) => {
      if (typeof str === 'undefined' || str === null) {
          return '';
      }
      const breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
      return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }
  }
}
